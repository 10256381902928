
import { defineComponent } from "vue";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "Inquiry",
  mounted() {
    this.initMkto();
  },
  unmounted() {
    this.destroyMkto();
  },
  setup() {
    useHead({
      title: "도입문의 - 카카오 i 커넥트 라이브"
    });

    return {};
  },
  methods: {
    sleep: (ms: number) => new Promise(r => setTimeout(r, ms)),

    async initMkto() {
      const mkto = document.createElement("script");
      mkto.innerText =
        'MktoForms2.loadForm("//pages.kakaoenterprise.com", "714-QAI-126", 1973);';
      document.body.append(mkto);

      await this.sleep(300);

      const submitButton = document.querySelector(".mktoButton");
      submitButton?.addEventListener("click", () => {
        gtagEvents("문의 등록하기");
        // this.$router.push({ path: "/inquiry/success" });
      });
    },

    destroyMkto() {
      const mkto = document.querySelector("#mktoForm_1973");
      if (mkto) {
        mkto.remove();
      }
    }
  }
});
